<template>
  <Transition>
    <div class="trending-checkboxes">
      <v-layout :key="box.text" v-for="box in checkboxes">
        <v-checkbox
          :hide-details="true"
          class="shrink mr-0 mt-0 xs"
          :value="box.text"
          v-model="selected"
          :label="box.text"
          :append-icon="box.icon"
        ></v-checkbox>
      </v-layout>
    </div>
  </Transition>
</template>

<style scoped>
.slider {
  width: 100%;
}
i {
  float: left;
}
</style>
<script lang="ts">
import { CheckboxState } from "@/store/modules/analysis/types";
import { defineComponent } from "vue";
import { PropType } from "vue";

export default defineComponent({
  name: "CheckBoxGroup",
  emits: ["change"],
  props: {
    checkboxes: { type: Array as PropType<CheckboxState[]>, required: true },
  },
  watch: {
    selected: {
      handler: "onValueChange",
      deep: true,
    },
  },
  data: () => ({
    selected: [] as string[],
  }),
  mounted() {
    if (this.checkboxes) {
      this.selected = this.checkboxes.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.text);
    }
  },
  methods: {
    onValueChange() {
      this.$emit("change", this.selected);
    },
  },
});
</script>
