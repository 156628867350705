import * as api from "@/api";
import { State as RootState } from "@/store/index";
import { ActionContext, ActionTree, Module, MutationTree } from "vuex";
import { Label, LabelsState, ViewLabel } from "./types";

const getDefaultState = () => ({
  labels: {},
  viewLabels: {},
  analysisLabels: {},
});

// Mutations
const mutations: MutationTree<LabelsState> = {
  resetState: (state) => {
    state = Object.assign(state, getDefaultState());
  },
  setLabels: (state, labels: Record<string, Label>) => {
    state.labels = labels;
  },
  appendLabels: (state, labels: Record<string, Label>) => {
    state.labels = { ...state.labels, ...labels };
  },
  setViewLabels: (state, data: { viewId: number; viewLabels: ViewLabel[] }) => {
    state.viewLabels[data.viewId] = data.viewLabels;
  },
  setLabelsForAnalysis: (state, data: { analysisId: number; labels: Label[] }) => {
    state.analysisLabels[data.analysisId] = data.labels;
  },
  appendLabelsForAnalysis: (state, data: { analysisId: number; labels: Label[] }) => {
    const { analysisId, labels } = data;
    const existingLabels = state.analysisLabels[data.analysisId] ?? [];
    const existingLabelIds = new Set(existingLabels.map((lbl) => lbl.id));
    const newLabels = labels.filter((label) => !existingLabelIds.has(label.id));
    state.analysisLabels[analysisId] = existingLabels.concat(newLabels);
  },
};

// Actions
const actions: ActionTree<LabelsState, RootState> = {
  async fetchLabels({ commit }: ActionContext<LabelsState, RootState>) {
    const r = await api.fetchLabels();
    if (r.status === 200) {
      commit("setLabels", Object.fromEntries(r.data.map((label: Label) => [label.text, label])));
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
  },

  async createLabel({ state, commit }: ActionContext<LabelsState, RootState>, label: string) {
    const r = await api.createLabel(label);
    if (r.status >= 200) {
      commit("setLabels", { ...state.labels, [label]: { text: label } });
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
  },
  async updateLabel(
    { state, commit }: ActionContext<LabelsState, RootState>,
    data: { oldLabel: string; newLabel: string }
  ) {
    const r = await api.updateLabel(data.oldLabel, data.newLabel);
    if (r.status >= 200) {
      const labels = { ...state.labels };
      delete labels[data.oldLabel];
      labels[data.newLabel] = { text: data.newLabel, id: r.data.id };
      commit("setLabels", labels);
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
  },

  async fetchViewLabels({ commit }: ActionContext<LabelsState, RootState>, data: { viewId: number }) {
    const r = await api.fetchViewLabels(data.viewId);
    const viewLabels = r.data;
    commit("setViewLabels", { viewId: data.viewId, viewLabels });
  },

  async saveViewLabels(
    { commit, dispatch }: ActionContext<LabelsState, RootState>,
    data: { viewId: number; viewLabels: ViewLabel[] }
  ) {
    const { viewId, viewLabels } = data;
    const labelsByLevel: { [level: number]: string[] } = {};
    const output: { level: number; labels: string[] }[] = [];
    viewLabels.forEach((label) => {
      if (!labelsByLevel[label.level]) {
        labelsByLevel[label.level] = [];
      }
      labelsByLevel[label.level].push(label.text);
    });
    Object.entries(labelsByLevel).forEach(([level, labels]) => {
      output.push({ level: parseInt(level), labels });
    });
    const r = await api.updateViewLevels(viewId, output);
    if (r.status === 200) {
      commit("setViewLabels", { viewId, viewLabels });
      commit("analysis/resetKeywordLabelsForLevels", viewId, { root: true });
      dispatch(
        "selectView",
        {
          viewId: viewId,
          level: 1,
        },
        { root: true }
      );
    }
  },
  async fetchLabelsInAnalysis(
    { commit, dispatch }: ActionContext<LabelsState, RootState>,
    analysisId: number
  ) {
    const r = await api.fetchLabelsInAnalysis(analysisId);
    if (r.status === 200) {
      commit("setLabelsForAnalysis", { analysisId, labels: r.data });
    }
  },
};

const labelsModule: Module<LabelsState, RootState> = {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
};

export default labelsModule;
