<template>
  <!-- <p v-if="label" class="label strong text-primary s">{{ label }}</p> -->
  <div
    v-show="barData?.length > 0"
    ref="barElement"
    style="height: 100%"
    :class="`chart-paper ${loading ? 'loading' : ''}`"
  >
    <div
      v-show="barDataTop10?.length > 0 && options && series?.length > 0"
      style="height: 100%"
      class="barchart"
    >
      <apexchart
        ref="chart"
        :options="options"
        :series="series"
        height="100%"
        @dataPointSelection="handleClick"
      />
    </div>
    <div class="show-all-btn" v-if="barData?.[0]?.data?.length > 10">
      <MaireButton
        compact
        inverse
        :text="`${barData?.[0].data?.length > 100 ? 'Show top 100' : 'Show all'}`"
        @click="toggleShowAll"
      />
    </div>
    <div class="show-all-btn" v-if="barData?.[0]?.data?.length <= 10">
      <MaireButton
        compact
        inverse
        :text="`Full screen`"
        @click="toggleShowAll"
      />
    </div>
  </div>
  <div class="modal" v-if="showAll">
    <div class="modal-content">
      <p v-if="label" class="strong modal-header text-primary s">{{ label }}</p>
      <div class="modal-graph">
        <apexchart
          :options="barOptions"
          :series="barData"
          :height="barData?.[0]?.data?.length > 10 ? barHeight : undefined"
          @dataPointSelection="handleClick"
        />
      </div>
      <div class="close-modal-btn" @click="toggleShowAll">
        <v-icon>fal fa-times-circle</v-icon>
      </div>
    </div>
  </div>
</template>
<style scoped>
.chart-paper {
  transition: filter 0.5s ease-in-out;
}
.loading {
  filter: blur(4px) brightness(80%);
}
.loading-animation {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.modal {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  position: relative;
  background-color: white;
  padding: 36px;
  margin-top: 36px;
  border: 1px solid #888;
  width: 60%;
  height: 80%;

  margin: 5% auto;
}
.modal-graph {
  max-height: 100%;
  padding: 12px;
  overflow: scroll;
  scrollbar-width: thin; /* 'auto' or 'thin' */
  scrollbar-color: rgb(var(--v-theme-mairePurple)) transparent; /* color of the thumb and track */
}

/* For Webkit (Chrome, Safari, Edge) */
.modal-graph::-webkit-scrollbar {
  width: 10px; /* Adjust the width of the scrollbar */
  border-radius: 5px; /* Round the corners of the scrollbar */
}

.modal-graph::-webkit-scrollbar-track {
  background: transparent; /* Track background */
  border-radius: 5px;
}

.modal-graph::-webkit-scrollbar-thumb {
  background-color: rgb(var(--v-theme-mairePurple)); /* Your desired shade of purple */
  border-radius: 5px; /* Round the corners of the scrollbar thumb */
}

.modal-graph::-webkit-scrollbar-thumb:hover {
  background-color: rgb(var(--v-theme-mairePurple)); /* Your desired shade of purple */
}

.close-modal-btn {
  color: rgb(var(--v-theme-mairePurple));
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 12px;
}
.show-all-btn {
  position: relative;
  float: left;
  bottom: 0;
  padding-left: 0px;
  /* height: 150px; */
  width: 120px;
}
.label {
  margin-right: 200px;
}
</style>
<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  getLabelColor,
  numberFormatter,
  capitalizeFirstLetter,
  isSorted,
} from "@/components/helpers/helpers";
import { topKElements } from "@/components/helpers/MinHeap";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import { GrowthStat } from "@/composables/worker";
import { VueApexChartsComponent } from "vue3-apexcharts";

export default defineComponent({
  emits: ["select", "unselect"],
  props: {
    id: {
      type: Number,
      required: true,
    },
    stats: {
      type: Array as () => GrowthStat[],
      required: true,
    },
    field: {
      type: String,
      required: false,
      default: "potential",
    },
    postFix: {
      type: String,
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    showAll: false,
    barElement: ref(null),
    sortedLabelData: [] as GrowthStat[],
    legendWidth: 600,
    series: [] as any[],
    chart: ref(null) as any,
    options: undefined as any,
  }),
  watch: {
    stats: {
      handler() {
        if (this.loading) return;
        setTimeout(this.processChart);
      },
      immediate: true,
    },
  },
  mounted() {
    const el = this.$refs.barElement as HTMLElement;
    this.chart = this.$refs.chart as VueApexChartsComponent;
    // @ts-ignore
    if (el?.clientWidth) {
      this.legendWidth = el.clientWidth * 0.55;
    }
    this.options = this.barOptions;
    this.series = this.barDataTop10;
  },
  computed: {
    barData() {
      return [
        {
          name: capitalizeFirstLetter(this.field),
          data: this.sortedLabelData.map((label: any) => ({
            x: label.label,
            y: label?.[this.field],
            fillColor: getLabelColor(label.label),
          })),
        },
      ];
    },
    barDataTop10() {
      return [
        {
          name: capitalizeFirstLetter(this.field),
          data: this.sortedLabelData.slice(0, 10).map((label: any) => ({
            x: label.label,
            y: label?.[this.field] || 0,
            extra: label,
            fillColor: getLabelColor(label.label),
          })),
        },
      ];
    },
    barHeight() {
      return (this.sortedLabelData?.length || 20) * 25;
    },
    barOptions() {
      const postFix = this.postFix;
      let max = 0; // or some suitable minimum value depending on the data type
      let maxCount = 0; // This is used to calculate the padding for the yaxis labels
      const extras = [] as GrowthStat[];
      for (const label of this.sortedLabelData) {
        const fieldValue = label?.[this.field as keyof GrowthStat] as number;
        if (label.count && label.count > maxCount) {
          maxCount = label.count;
        }
        if (fieldValue > max) {
          max = fieldValue; // update max if the current field value is larger
        }
        extras.push(label); // collect count values
      }
      maxCount = maxCount.toString().length;
      return {
        chart: {
          type: "bar",
          animations: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: false,
            borderRadius: 10,
            colors: {
              backgroundBarColors: ["#14ccc9"],
              backgroundBarOpacity: 0.08,
              backgroundBarRadius: 10,
            },
            borderRadiusApplication: "around",
            borderRadiusWhenStacked: "last",
          },
        },
        grid: {
          show: false,
          padding: {
            left: Math.max(48, maxCount * 12 + 12), // Account for the extra width caused by the count in parenthesis
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          offsetX: 0,
          dropShadow: {
            enabled: true,
          },
          background: {
            enabled: true,
            // foreColor: "#ffffff",
            borderRadius: 4,
            padding: 4,
            opacity: 0.2,
            borderWidth: 0,
          },
          style: {
            fontSize: "12px",
            fontWeight: "bold",
            colors: ["#000000"],
          },
          formatter: (val: number, { dataPointIndex }: { dataPointIndex: number }) => {
            if (val === 0) return "0";
            let extra = "";
            let prefix = "";
            const extraValues = extras[dataPointIndex];
            if (this.field !== "trending" && !this.field.includes("growth")) {
              const growth = extraValues?.growth;
              extra = `(${growth > 0 ? "+" : ""}${numberFormatter(growth, 2)}%)`;
            } else if (this.field.includes("Absolute")) {
              const relativeGrowthKey = this.field.replace("Absolute", "") as keyof GrowthStat;
              if (val > 0) prefix = "+";
              extra = `(${numberFormatter(extraValues[relativeGrowthKey] as number, 2, true)}%)`;
            } else if (this.field.includes("growth")) {
              const absoluteGrowth = extraValues?.absoluteGrowth;
              extra = `(${numberFormatter(absoluteGrowth as number, 2, true)})`;
            }
            return `${prefix}${numberFormatter(val, 2)}${postFix} ${extra ? extra : ""}`;
          },
        },
        tooltip: {
          enabled: true,
          followCursor: true,
          y: {
            formatter: (val: number, { dataPointIndex }: { dataPointIndex: number }) => {
              if (val === 0) return "0";
              let extra = "";
              if (this.field === "trending") {
                extra = `(Monthly average volume: ${numberFormatter(extras[dataPointIndex]?.volume, 2)})`;
              }
              return `${numberFormatter(val, 2)}${postFix} ${extra ? extra : ""}`;
            },
          },
        },
        xaxis: {
          type: "numeric",
          // hide the axis, show:false doesn't work,
          floating: true,
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function (val: number, index: any) {
              const count = extras[index?.dataPointIndex]?.count;
              if (!count) {
                return val;
              } else {
                return `${val} (${count})`;
              }
            },
          },
        },
        theme: {
          monochrome: {
            enabled: false,
          },
        },
      };
    },
    barOptionsNoAnimation() {
      return {
        ...this.barOptions,
        chart: {
          ...this.barOptions.chart,
          animations: {
            enabled: false,
          },
        },
      };
    },
  },
  methods: {
    calculateSortedLabelData() {
      let sorted = [];
      if (isSorted(this.stats, this.field)) {
        sorted = this.stats.slice(0, 100);
      } else {
        sorted = topKElements(this.stats, 100, this.field as keyof GrowthStat);
      }
      this.sortedLabelData = sorted;
      return sorted;
    },
    processChart() {
      this.calculateSortedLabelData();
      this.chart?.chart?.updateOptions(
        {
          dataLabels: this.barOptions.dataLabels,
          yaxis: this.barOptions.yaxis,
          tooltip: this.barOptions.tooltip,
          series: this.barDataTop10,
          grid: this.barOptions.grid,
        },
        false,
        true,
        false
      );
    },
    toggleShowAll() {
      // attach listener for escape key
      if (!this.showAll) {
        document.addEventListener("keydown", this.handleEscapeKey);
      } else {
        document.removeEventListener("keydown", this.handleEscapeKey);
      }
      this.showAll = !this.showAll;
    },
    handleEscapeKey(event: KeyboardEvent) {
      if (event.key === "Escape") {
        this.toggleShowAll();
      }
    },
    handleClick(_event: any, _chartContext: any, config: any) {
      const selectedIndex = config?.dataPointIndex;
      const label = config?.w?.config?.series?.[0]?.data?.[selectedIndex]?.x;
      this.$emit("select", label);
    },
  },
  components: {
    MaireButton,
    // WaitAnimation,
  },
});
</script>
