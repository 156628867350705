import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22edfaf2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_range_slider = _resolveComponent("v-range-slider")!

  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_range_slider, {
          modelValue: _ctx.slider,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.slider) = $event)),
          color: "#14CCC9",
          "track-size": "2",
          max: _ctx.sliderState.rangeMax,
          min: 0,
          step: _ctx.step
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.slider[0],
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.slider[0]) = $event)),
              "hide-details": "",
              type: "number",
              class: "slider-input slider-min",
              variant: "outlined",
              density: "compact",
              style: {"width":"80px"}
            }, null, 8, ["modelValue"])
          ]),
          append: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.slider[1],
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.slider[1]) = $event)),
              "hide-details": "",
              type: "number",
              class: "slider-input slider-min",
              variant: "outlined",
              style: {"width":"80px"},
              density: "compact"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["modelValue", "max", "step"])
      ])
    ]),
    _: 1
  }))
}