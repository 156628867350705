import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f8b89fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search_bar" }
const _hoisted_2 = {
  key: 0,
  class: "avatar-container"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_text_field, {
      modelValue: _ctx.searchWord,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchWord) = $event)),
      label: "Search items",
      density: "compact",
      clearable: "",
      "append-inner-icon": _ctx.searchWord ? '' : 'mdi:mdi-magnify',
      class: "search_bar",
      variant: "outlined"
    }, null, 8, ["modelValue", "append-inner-icon"]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.magicSearchEnabled && (_ctx.searchWord || _ctx.thinking))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("img", {
                src: `${_ctx.publicPath}images/maire_avatar.png`,
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.magicSearch && _ctx.magicSearch(...args))),
                alt: "Avatar",
                class: _normalizeClass(`${_ctx.thinking ? 'thinking' : ''} avatar`)
              }, null, 10, _hoisted_3)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}