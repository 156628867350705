<template>
  <div class="company-preferences">
    <v-sheet v-if="modalOpen" class="inputs" id="inputs">
      <v-container>
        <v-row justify="space-between" dense>
          <v-col cols="2">
            <v-text-field
              prepend-inner-icon="fa-regular fa-globe"
              density="compact"
              :center-affix="true"
              :rounded="true"
              v-model="userSearch.domain"
              variant="outlined"
              persistent-placeholder
              placeholder="www.example.com"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              prepend-inner-icon="fa-regular fa-coins"
              density="compact"
              type="number"
              v-model="userSearch.customer_average_purchase"
              variant="outlined"
              persistent-placeholderlabel="Average Purchase"
              placeholder="150$"
              label="Average purchase"
              required
            >
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              prepend-inner-icon="fa-regular fa-location-dot"
              density="compact"
              v-model="userSearch.location"
              :items="locations"
              label="Location"
              variant="outlined"
              :hide-details="true"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              prepend-inner-icon="fa-regular fa-language"
              density="compact"
              v-model="userSearch.language"
              :items="languages"
              label="Language"
              variant="outlined"
              :hide-details="true"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <!-- <v-combobox class="text-primary" v-model="company.keywords" :items="[]" label="Descriptive words"
            variant="outlined" multiple></v-combobox> -->
            <v-text-field
              prepend-inner-icon="fa-regular fa-key-skeleton"
              density="compact"
              persistent-placeholder
              @keydown="handleDescriptiveWordKeypress"
              @paste="handleDescriptiveWordPaste"
              v-model="keyword"
              label="Descriptive words"
              placeholder="Add words here and press enter"
              variant="outlined"
              clearable
              outlined
            >
            </v-text-field>
            <!-- <v-text-field class="text-primary custom-placeholer-color" v-model="company.keywords" variant="filled"
              persistent-placeholder label="Keywords" :placeholder=company.keywords required></v-text-field> -->
            <div class="keyword-chips">
              <v-chip
                @click:close="() => removeDescriptiveWord(keyword as string)"
                :v-bind="keyword"
                :key="keyword"
                v-for="keyword in userSearch.descriptive_words"
                class="ma-1 keyword-chip"
                closable
              >
                {{ keyword }}
              </v-chip>
              <div class="inline" v-if="loadingWordSuggestions">
                <p class="inline" style="padding-left: 10px; padding-right: 20px; white-space: nowrap">
                  <v-progress-circular style="height: 20px" indeterminate color="#8200FF" />We're fetching
                  some suggestions for you
                </p>
              </div>
              <Transition>
                <div v-if="company.wordSuggestions && !loadingWordSuggestions" class="inline">
                  <v-chip
                    :key="word"
                    v-for="word in company.wordSuggestions.slice(0, 5)"
                    @click="(e: PointerEvent) => addSuggestion(e, word)"
                    class="ma-1 chip-add"
                  >
                    <v-icon style="margin-right: 5px">mdi:mdi-plus</v-icon>{{ word }}
                    <v-icon @click="() => removeWordSuggestion(word)" style="margin-left: 5px"
                      >mdi:mdi-close-circle-outline</v-icon
                    >
                  </v-chip>
                </div>
              </Transition>
              <v-chip
                v-if="
                  !loadingWordSuggestions &&
                  userSearch.descriptive_words &&
                  userSearch.descriptive_words?.length > 1
                "
                @click="toggleSuggestionModal"
                class="ma-1 chip-inverse"
              >
                Clear all
                <v-icon style="margin-left: 5px">mdi:mdi-close-circle-outline</v-icon>
              </v-chip>
              <MaireDialog
                text="Are you sure you want to clear everything?"
                :open="suggestionModalOpen"
                :onClose="toggleSuggestionModal"
                :fn="removeAllDescriptiveWords"
              >
              </MaireDialog>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <div class="buttons" cols="12">
            <button @click="toggleModal" class="button"></button>
            <v-icon v-if="modalOpen" @click="toggleModal" class="close-button" variant="outlined">
              fas fa-times-circle
            </v-icon>
            <v-btn
              v-if="modalOpen"
              style="margin-right: -150px"
              @click="saveCompany"
              class="edit-button"
              variant="outlined"
              color="primary"
            >
              Update my choices
            </v-btn>
          </div>
        </v-row>
      </v-container>
    </v-sheet>
    <v-container class="selection-container" v-if="!modalOpen">
      <v-row justify="space-around">
        <v-col cols="1">
          <div class="preference domain-field feather-effect">
            <v-icon class="inline">fa-regular fa-globe</v-icon>
            <span style="margin-left: 10px; max-width: 400px">{{ userSearch.domain }}</span>
          </div>
        </v-col>
        <v-col cols="1">
          <div class="preference">
            <v-icon class="inline">fa-regular fa-coins</v-icon>
            <p class="inline" style="margin-left: 10px">{{ userSearch.customer_average_purchase }}€</p>
          </div>
        </v-col>
        <v-col cols="1">
          <div class="preference">
            <v-icon class="inline">fa-regular fa-location-dot</v-icon>
            <p class="inline" style="margin-left: 10px">
              {{ userSearch.location }}
            </p>
          </div>
        </v-col>
        <v-col cols="1">
          <div class="preference">
            <v-icon class="inline">fa-regular fa-language</v-icon>
            <p class="inline" style="margin-left: 10px">
              {{ userSearch.language }}
            </p>
          </div>
        </v-col>
        <v-col cols="5">
          <div class="preference">
            <v-icon class="inline">fa-regular fa-key-skeleton</v-icon>
            <p class="inline" style="margin-left: 10px">
              {{ userSearch?.descriptive_words?.join(", ").slice(0, 60)
              }}{{ (userSearch?.descriptive_words || [])?.join(", ").length > 60 ? "..." : "" }}
            </p>
          </div>
        </v-col>
        <v-col cols="2">
          <v-btn
            plain
            v-if="!modalOpen"
            @click="toggleModal"
            style="margin-right: -0px"
            class="edit-button"
            variant="outlined"
            color="primary"
          >
            Edit my choices
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style scoped>
.unselected-label {
  filter: opacity(50%);
}
.domain-field {
  position: relative;
  overflow: hidden;
  margin-right: -40px;
}
span {
  font-size: 14px;
  font-family: "Nunito Sans";
}

.feather-effect:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  background: linear-gradient(to left, rgb(var(--v-theme-maireDarkBlue)), transparent);
}
.main_container {
  margin-right: -50px;
}
.secondary_container {
  margin-left: 50px;
  margin-top: 100px;
}
.search_container {
  width: 400px;
  position: relative;
  float: right;
  display: inline;
  margin-bottom: -4px;
}
.preference {
  /* put in the middle of parent */
  display: flex;
  align-items: center;
  padding-top: 12px;
  white-space: nowrap;
}

.revenue_card {
  margin-top: 5px;
}
.center {
  display: flex;
  align-items: center;
  margin-left: 0px;
  /* align to LEFT horizontally */
}

.company-preferences strong,
.company-preferences p {
  color: white;
}

.company-preferences {
  background-color: rgb(var(--v-theme-maireDarkBlue));
  margin-bottom: 20px;
}

.custom-placeholer-color input::placeholder {
  color: rgb(0, 0, 136) !important;
  opacity: 1;
  font-size: 14px;
}

.descriptive_words_container {
  margin-right: -55px;
}

.inputs {
  /* margin-bottom: -95px; */
  margin-right: 150px;
  background-color: #f7f7f7;
  background-color: rgb(var(--v-theme-maireDarkBlue));
}

.inputs input {
  color: white !important;
}

.selection-container {
  min-height: 80px;
  color: white;
  display: flex;
  align-items: top;
  justify-content: top;
}

.keyword-chips {
  margin-top: -35px;
  margin-bottom: 20px;
  max-height: 300px;
  position: relative;
  color: rgba(255, 255, 255, 0.9);
}

.keyword-chip {
  background-color: #8200ff;
}

.keyword-chip i {
  color: rgba(132, 0, 255, 0.7) !important;
}

.chip-inverse {
  background-color: white;
  border: 1px #8200ff solid;
  color: #8200ff;
}

.chip-inverse i {
  color: #a74bff;
  font-size: 18px;
}

.chip-add {
  background-color: white;
  border: 1px rgba(62, 84, 211, 0.7) solid;
  color: rgba(62, 84, 211, 0.7);
  /* Start the shake animation and make the animation last for 0.5 seconds */
  /* animation: shake 2s ease-in; */
  cursor: pointer;
  /* When the animation is finished, start again */
  /* animation-iteration-count: infinite; */
}

.chip-add i {
  color: rgba(62, 84, 211, 0.7);
  font-size: 18px;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, 0px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, -1px) rotate(1deg);
  }

  30% {
    transform: translate(-1px, -2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(3px, 0px) rotate(-1deg);
  }

  60% {
    transform: translate(-1px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(-3px, 2px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, 1px) rotate(1deg);
  }

  90% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  100% {
    transform: translate(1px, 0px) rotate(-1deg);
  }
}

.buttons {
  position: relative;
  display: block;
  margin: auto;
  right: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.maire-purple {
  border-color: #8200ff;
}

.maire-violet {
  border-color: #b500ff;
}

.maire-teal {
  border-color: #14ccc9;
}

.close-button {
  position: fixed;
  top: 0px;
  right: 0px;
  margin-top: 20px;
  margin-right: 100px;
  font-size: 24px;
  top: 0;
}

.edit-button {
  border-radius: 12px;
  border-style: none;
  padding: -4px 16px !important;
  height: 43px;
  margin-right: 10px;
  color: white !important;
  float: right;
  position: relative;
  text-transform: none;
  background-color: rgb(var(--v-theme-mairePink));
  z-index: 2;
}

.edit-button:hover {
  /* background-color: rgb(var(--v-theme-mairePurple)) !important; */
  filter: brightness(1.1);
}

.keyword-research-text-container {
  padding: 20px 0px 12px 0px;
  width: 100%;
}
</style>
<script lang="ts">
// import SearchBar from "@/components/DataTable/SearchBar.vue";
// import KeywordTable from "@/components/KeywordTable/KeywordTable.vue";
// import KeywordFilters from "@/components/KeywordFilters/KeywordFilters.vue";
// import RevenueCard from "@/components/RevenueCard/RevenueCard.vue";
import { defineComponent } from "vue";
import { Keyword } from "@/store/modules/keywords/types";
import { SUPPORTED_COUNTRIES, SUPPORTED_LANGUAGES } from "@/components/helpers/geo_constants";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import { filterKeywords } from "@/components/helpers/helpers";
import { useStore } from "@/store"

export default defineComponent({
  data: () => ({
    modalOpen: false,
    companyPrevious: {},
    el: "#inputs",
    selectedIntent: "Your Best Opportunities",
    suggestionModalOpen: false,
    keyword: "",
    questions: [
      {
        title: "Domain",
        placeholder: "www.paperfactory.com",
        width: 2,
      },
      {
        title: "Average purchase",
        placeholder: "150$",
        width: 2,
      },
      {
        title: "Location",
        placeholder: "Finnish",
        width: 2,
        type: "autocomplete",
      },
      {
        title: "Language",
        placeholder: "Helsinki, Finland",
        width: 2,
        type: "autocomplete",
      },
      {
        title: "Keywords",
        placeholder:
          "Paper manufacturing, Paper factory in Helsinki, SME, Office supplier, School supplier , Paper sellers, Paper supply chain",
        width: 4,
      },
    ],
    locations: SUPPORTED_COUNTRIES,
    languages: SUPPORTED_LANGUAGES,
    selectedLocation: [],
    selectedLanguage: [],
  }),
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    handleDescriptiveWordKeypress(event: KeyboardEvent) {
      if (["Enter", ","].includes(event.key)) {
        this.addDescriptiveWord();
        event.preventDefault();
      }
    },
    handleDescriptiveWordPaste(event: ClipboardEvent) {
      event.preventDefault();
      const text = event.clipboardData?.getData("text");
      if (text) {
        this.keyword = text;
        this.addDescriptiveWord();
      }
    },
    setSelectedIntent(intent: string) {
      this.selectedIntent = intent;
    },
    toggleSuggestionModal() {
      this.suggestionModalOpen = !this.suggestionModalOpen;
    },
    toggleModal() {
      if (!this.modalOpen) {
        this.companyPrevious = structuredClone(this.store.state.company);
      } else {
        this.store.state.company = this.companyPrevious;
      }
      this.modalOpen = !this.modalOpen;
    },
    saveCompany() {
      this.modalOpen = !this.modalOpen;
      if (this.userSearch?.descriptive_words?.length === 0 && this.keyword.length > 0) {
        this.store.dispatch({ type: "addDescriptiveWord", keyword: this.keyword });
      }
      this.store.dispatch({ type: "updateUserSearch" });
    },
    removeWordSuggestion(suggestion: string) {
      this.store.dispatch({ type: "removeWordSuggestion", suggestion: suggestion });
    },
    removeDescriptiveWord(keyword: string) {
      this.store.dispatch({ type: "removeDescriptiveWord", keyword: keyword });
    },
    removeAllDescriptiveWords() {
      this.toggleSuggestionModal();
      this.store.dispatch({ type: "removeAllDescriptiveWords" });
    },
    addSuggestion(e: any, word: string) {
      if (!e.target.className.includes("mdi-close")) {
        this.store.dispatch({ type: "addDescriptiveWord", keyword: word });
      }
    },
    addDescriptiveWord() {
      const keywords = this.keyword
        .split(",")
        .map((word) => word.trim())
        .filter(Boolean);
      if (keywords?.length > 0) {
        this.store.dispatch("fetchWordSuggestions", {
          keyword: keywords[0],
          language: this.userSearch.language,
        });
        keywords.forEach((keyword) => {
          this.store.dispatch({ type: "addDescriptiveWord", keyword });
        });
        this.keyword = "";
      }
    },
  },
  components: {
    MaireDialog,
  },
  mounted() {
    this.$el.addEventListener("keyup", (event: KeyboardEvent) => {
      if (this.modalOpen && event.code === "Escape") {
        this.toggleModal();
      }
    });
    this.$el.addEventListener("keydown", (event: KeyboardEvent) => {
      if (this.modalOpen && event.metaKey && event.code === "Enter") {
        this.saveCompany();
      }
    });
  },
  unmounted() {
    this.$el.removeEventListener("keydown", KeyboardEvent);
    this.$el.removeEventListener("keyup", KeyboardEvent);
  },
  computed: {
    keywordsInViewText() {
      if (this.filteredKeywords?.length === this.keywords.length) {
        return `Keyword discovery (${this.keywords.length})`;
      } else {
        return `Keyword discovery (Showing ${this.filteredKeywords.length} of ${this.keywords.length})`;
      }
    },
    searchWord() {
      return this.store.state.searchWord;
    },
    drawerMargin() {
      return this.modalOpen ? -60 : -350;
    },
    drawerOpacity() {
      return this.modalOpen ? 1 : 0;
    },
    keywords() {
      return this.store.state.keywords.discoveryKeywords;
    },
    potentialRevenue() {
      const topKeywords = [...this.keywords]
        .sort((a: Keyword, b: Keyword) => b.potential - a.potential)
        .slice(0, 100);
      return topKeywords.reduce((prev: number, curr: Keyword) => {
        return prev + curr.potential;
      }, 0);
    },
    company() {
      return this.store.state.company;
    },
    userSearch() {
      return this.store.state.userSearch;
    },
    loadingWordSuggestions() {
      return this.store.state.loading.loadingWordSuggestions;
    },
    isAdmin() {
      return !!(this.store.state.user.type === "admin" || this.store.state.user.impersonator);
    },
    filters() {
      return this.store.state.analysis.currentFilters;
    },
    filteredKeywords() {
      return filterKeywords(this.keywords, this.filters) as Keyword[];
    },
  },
});
</script>
