<template>
  <div @click="(e) => handleClick(label, e)" :class="`inline  ${selected ? 'selected' : 'unselected'}`">
    <v-chip
      @click:close="(e: any) => handleClose && handleClose(e, label)"
      :style="{ 'background-color': getLabelColor(label), color: 'white' }"
      v-if="true"
      :closable="closable"
      class="label"
    >
      <span :class="`text-truncate label-content`">
        {{ label }}
        <v-tooltip top activator="parent" location="top">{{ label }}</v-tooltip>
      </span>
    </v-chip>
  </div>
</template>
<style scoped>
.label span {
  max-width: 250px;
}
.label {
  cursor: pointer;
}

.label {
  margin: 2px;
}

p {
  color: white;
  font-size: 12px;
}
.unselected {
  filter: opacity(0.4) brightness(0.8) grayscale(0.8);
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { getLabelColor } from "@/components/helpers/helpers";
export default defineComponent({
  name: "MaireChip",
  props: {
    handleClick: {
      type: Function,
      required: true,
    },
    handleClose: {
      type: Function,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: true,
    },
    closable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    getLabelColor,
  },
});
</script>
