import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c22ac50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "trending-checkboxes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_layout = _resolveComponent("v-layout")!

  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkboxes, (box) => {
          return (_openBlock(), _createBlock(_component_v_layout, {
            key: box.text
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_checkbox, {
                "hide-details": true,
                class: "shrink mr-0 mt-0 xs",
                value: box.text,
                modelValue: _ctx.selected,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
                label: box.text,
                "append-icon": box.icon
              }, null, 8, ["value", "modelValue", "label", "append-icon"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ])
    ]),
    _: 1
  }))
}