import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08e0b463"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"height":"100%"}
}
const _hoisted_2 = {
  key: 0,
  class: "loading-animation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!
  const _component_WaitAnimation = _resolveComponent("WaitAnimation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.pieData?.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          ref: "pieElement",
          style: {"height":"100%"},
          class: _normalizeClass(`chart-paper ${_ctx.loading ? 'loading' : ''}`)
        }, [
          (_ctx.rendered)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_apexchart, {
                  options: _ctx.pieOptions,
                  series: _ctx.pieData,
                  ref: "pieChart",
                  height: "100%",
                  onDataPointSelection: _ctx.handleClick
                }, null, 8, ["options", "series", "onDataPointSelection"])
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_WaitAnimation, { message: "Analyzing" })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}