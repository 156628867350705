<template>
  <ActionBar
    v-show="!localLoading"
    :viewId="viewId"
    :selectedLevel="level"
    :changeLevel="changeLevel"
    :overrideRecommendedLevel="overrideRecommendedLevel"
    :cancelOverride="resetLevel"
    :loading="isLoadingAnalysis"
  />
  <v-container>
    <div v-show="isLoadingAnalysis" class="wait-animation">
      <wait-animation />
    </div>
    <div
      v-if="!localLoading && !isLoadingAnalysis && analyses.length === 0 && !isLoadingKeywords"
      class="information-paper"
    >
      <div class="information-paper-content">
        <h2>No analysis filters</h2>
        <p>You don't have any analysis filters. Please create a filter to see insights.</p>
      </div>
    </div>
    <div v-if="!localLoading && !isLoadingAnalysis && filterHasNoView" class="information-paper">
      <div class="information-paper-content">
        <h2>Analysis doesn't have a view</h2>
        <p>This analysis doesn't have a view. Please create a view for this filter to see insights.</p>
      </div>
    </div>
    <div
      v-if="
        !localLoading && !isLoadingAnalysis && (!structureExists || !keywords.length) && !isLoadingAnalysis
      "
      class="center-info"
    >
      <div class="megatron-animation">
        <img :src="`${publicPath}images/glass.gif`" />
      </div>
      <div>
        <div class="structure-missing" v-if="!structureExists">
          <h2>Choose labels to see data</h2>
          <p>Choose what you want to see on the right hand side.</p>
        </div>
        <div class="keywords-missing" v-if="!keywords.length">
          <h2>Attach keywords to the analysis</h2>
          <p>In the discovery view, search for relevant keywords and associate them with this analysis.</p>
        </div>
      </div>
    </div>
    <div
      v-show="!localLoading && !isLoadingAnalysis && !filterHasNoView && structureExists && keywords.length"
      id="main-analysis-view"
    >
      <div class="drawer">
        <!-- <strong class="l inline">Your insights</strong> -->
        <div
          class="inline-block btn"
          style="display: inline-block; width: 70%; margin-left: 24px; position: relative"
        >
          <span style="display: inline-block; max-width: 300px">
            <MaireButton
              :compact="true"
              @click="fetchPredictions"
              text="Fetch predictions"
              :disabled="labelsInFiltered && labelsInFiltered?.length > 25"
              :isLoading="!!isLoadingLabelVolumes"
              disabledMessage="Please ensure you have only up to 5 labels in your charts. "
            />
          </span>
        </div>
        <div class="drawer-content" :class="{ active: drawersOpen.includes('graphs') }">
          <v-row>
            <v-col :cols="widget.col" :key="widget.name" v-for="widget in filteredWidgets">
              <MaireChart
                :name="widget.name"
                :id="widget.id"
                :precalculatedStats="widget.precalculatedStats"
                :volumes="widget.volumes"
                :dataDateRange="dataDateRange"
                :timelineStats="widget.timelineData"
                :initialType="widget.type"
                :defaultField="widget.defaultField"
                :fields="widget.fields"
                :label="widget.name"
                :loading="widget.processing || !!processing"
                :level="widget.level"
                :defaultTimeframeText="widget.defaultTimeframeText"
                :onSelect="onLabelSelect"
                :onUnSelect="onLabelUnselect"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <v-row v-if="!isLoadingAnalysis && !(keywords.length === 0)">
        <v-col cols="12" class="container-margin">
          <div>
            <div class="title-and-search">
              <div class="mb-3 title-text"></div>
              <div class="search_container">
                <SearchBar />
              </div>
            </div>
            <div v-show="!isLoadingKeywords">
              <keyword-table :keywords="filteredKeywords" :viewId="viewId" :loading="processing" />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped>
#main-analysis-view {
  margin-top: 50px;
}
.center-info {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  text-align: center;
  transform: translate(0%, 50%);
}

.center-info p {
  text-align: center;
}
.information-paper {
  position: relative;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 70%;
  max-width: 600px;
  padding: 24px;
  background-color: white;
  border-radius: 0px !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.graphs {
  padding-top: 24px;
  padding-right: 24px;
}
.drawer-content {
  display: none;
  margin-top: 24px;
}
.drawer-content.active {
  display: block;
}
.drawer-chevron {
  position: relative;
  cursor: pointer;
  float: right;
}
.drawer-bar {
  width: 100%;
  cursor: pointer;
}
.container-margin {
  margin-top: 12px;
  margin-bottom: 12px;
}

.title-text {
  margin-bottom: 36px;
  padding-bottom: 20px !important;
  width: 70%;
  display: inline-block;
}
.search_container {
  width: 30%;
  float: right;
  position: relative;
  display: block;
}

.loading {
  filter: blur(4px) brightness(100%);
}
</style>
<script lang="ts" setup>
// import KeywordFilters from "@/components/KeywordFilters/KeywordFilters.vue";
import KeywordTable from "@/components/KeywordTable/KeywordTable.vue";
import SearchBar from "@/components/DataTable/SearchBar.vue";
import ActionBar from "@/components/ActionBar/ActionBar.vue";
import { GrowthStat, useWorker } from "@/composables/worker";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import MaireChart from "@/components/MaireChart/MaireChart.vue";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import { filterKeywords, recommendedLevelForFilters } from "@/components/helpers/helpers";
import { WidgetType, useStore } from "@/store";
import { Keyword, Volumes } from "@/store/modules/keywords/types";
import { Ref, computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ViewLabel } from "@/store/modules/labels/types";
import { Analysis, AnalysisFilters, LevelKeywordLabels } from "@/store/modules/analysis/types";
import { onMounted, onUnmounted } from "vue";

const BASE_URL = process.env.BASE_URL;

onUnmounted(() => {
  terminateWorker();
});
onMounted(async () => {
  if (analysisId.value && !viewId.value) {
    if (!analyses.value.length) {
      await store.dispatch("analysis/fetchAnalyses");
    }
    const defaultView = analysis.value?.views?.[0];
    if (analysis.value && defaultView) {
      router.push({ path: `/analysis/${analysis.value.id}`, query: { view: defaultView.id } });
    }
  }
});
const debounce = (func: Function, wait: number) => {
  let timeout: number | undefined;

  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => func(...args), wait);
  };
};
const { startWorker, terminateWorker } = useWorker();
const store = useStore();
const route = useRoute();
const router = useRouter();
const kws: Ref<Set<Keyword>> = ref(new Set());
const processing: Ref<boolean> = ref(true);
const overrideRecommendedLevel = ref(false);
const filterHasNoView: Ref<boolean> = ref(false);
const localLoading: Ref<boolean> = ref(true);
const publicPath = computed(() => BASE_URL);
const level = ref(1);
const recommendedLevel: Ref<number | undefined> = ref(1);
const currentLevel = computed(() => store.state.analysis.views?.[viewId.value]?.currentLevel);
const isLoadingKeywords = computed(
  () => store.state.loading.loadingKeywords || store.state.loading.loadingFavorites
);

const drawersOpen = ref(["labelFilters", "graphs"] as string[]);
const keywords = computed(() => store.state.keywords.analysisKeywords);

const isLoadingLabelVolumes = computed(
  () => filterId?.value && store.state.loading.loadingLabelVolumes.includes(filterId.value)
);

const filters = computed(() => store.state.analysis.currentFilters);
const analyses = computed(() => store.state.analysis.analyses);
const analysis = computed(() => store.state.analysis.analyses.find((af) => af.id === analysisId.value));
const viewId = computed(() => (route.query.view ? parseInt(route.query.view as string) : 0));
const analysisId = computed(() => parseInt(route.params.id as string));
const labelsInParams = computed(() =>
  route.query.labels
    ? new Set((route.query.labels as string).split(",").map((label) => decodeURIComponent(label)))
    : ""
);
const view = computed(() => {
  return viewId.value ? store.state.analysis.views?.[viewId.value] : undefined;
});

const structureExists = computed(() => {
  return !!store.state.labels.viewLabels?.[viewId.value]?.length;
});

const isLoadingAnalysis = computed(
  () => view.value && store.state.loading.loadingAnalysis?.includes(view.value.id)
);

const labelVolumes = computed(
  () => analyses.value.find((af: Analysis) => af.id === analysisId.value)?.labelstats
);

const labelVolumesForlabels = computed(() => {
  const result: { [key: string]: any } = {};
  const volumes = labelVolumes.value || {};
  for (const label in volumes) {
    if (labels2.value.includes(label)) {
      result[label] = volumes[label];
    }
  }
  return result;
});

const keywordsChanged = computed(() => store.state.keywords.triggerRecalculation);
const computeFilteredKeywords = (
  keywords: Keyword[],
  filters: AnalysisFilters,
  levelKwMap?: LevelKeywordLabels
) => {
  if (!keywords.length) return new Set<Keyword>();
  return filterKeywords(keywords, filters, true, levelKwMap) as Set<Keyword>;
};
watch(keywordsChanged, () => {
  if (!isLoadingAnalysis.value && keywordsChanged) {
    store.commit("keywords/setTriggerRecalculation", false);
    processing.value = true;
    setTimeout(() => {
      kws.value = computeFilteredKeywords(keywords.value, filters.value, view.value?.levelKwMap);
      setTimeout(calculateKeywordAnalysis, 0);
      debouncedProcessLabelStats();
    }, 0);
  }
});
watch(
  filters,
  async () => {
    recommendedLevel.value = filters.value.labelFilters.include.labels?.length
      ? recommendedLevelForFilters(filters.value)
      : 1;
    if (!isLoadingAnalysis.value) {
      processing.value = true;
      setTimeout(() => {
        kws.value = computeFilteredKeywords(keywords.value, filters.value, view.value?.levelKwMap);
        setTimeout(calculateKeywordAnalysis, 0);
        debouncedProcessLabelStats();
      }, 0);
    }
  },
  { deep: true, immediate: true }
);
const calculateKeywordAnalysis = async () => {
  const allLabels = new Set();
  const labelsSet = new Set();
  const filteredIds = [] as number[];
  keywords.value.forEach((curr: Keyword) => {
    curr.labels?.forEach((label: ViewLabel) => {
      allLabels.add(label.text);
    });
    if (kws.value.has(curr)) {
      filteredIds.push(curr.id);
      curr.labels?.forEach((label: ViewLabel) => {
        labelsSet.add(label.text);
      });
    }
  });
  labels2.value = Array.from(allLabels) as string[];
  labelsInFiltered.value = Array.from(labelsSet) as string[];
  filteredIds2.value = filteredIds;
};

const labels2: Ref<string[]> = ref([]);
const labelsInFiltered: Ref<string[]> = ref([]);
const filteredIds2: Ref<number[]> = ref([]);
const filteredKeywords = computed(() => Array.from(kws.value));
const dataDateRange: Ref<object> = ref({
  firstYear: 0,
  firstMonth: 0,
  latestYear: 0,
  latestMonth: 0,
});
const labelStats: Ref<GrowthStat[]> = ref([]);
const keywordStats: Ref<GrowthStat[]> = ref([]);
const volumes: Ref<{ [key: string]: { [key: string]: number[] } }> = ref({});
const chartLabelStats = computed(() => {
  return labelStats.value.filter((labelStat: GrowthStat) => labels2.value.includes(labelStat.label));
});
const chartVolumeStats = computed(() =>
  // only return keys in volumes that are in labels
  Object.fromEntries(Object.entries(volumes.value).filter(([label]) => labels2.value.includes(label)))
);
const widgets = computed(() => {
  // Combine loops for filtering keyword stats and old keywords
  const newKwStats = [] as GrowthStat[];
  const oldKwStats = [] as GrowthStat[];
  const oldKwStatsMap = {} as { [key: string]: Volumes };
  keywordStats.value.forEach((stat) => {
    if (stat.isNew) {
      newKwStats.push(stat);
    } else {
      oldKwStats.push(stat);
    }
  });

  const oldKws = filteredKeywords.value.filter((kw: Keyword) => !kw.isNew);
  oldKws.forEach((kw: Keyword) => {
    oldKwStatsMap[`${kw.keyword} (${kw.localization.location_name}/${kw.localization.language_code})`] =
      kw.volumes;
  });
  return [
    {
      id: 1,
      type: WidgetType.BarChart,
      name: `Volume by category label`,
      defaultTimeframeText: "Last 12 months",
      precalculatedStats: chartLabelStats.value,
      volumes: chartVolumeStats.value,
      defaultField: "volume",
      fields: [{ key: "volume", value: "Search volume" }],
      col: 4,
      level: "category",
    },
    {
      id: 2,
      type: WidgetType.BarChart,
      name: `Growth by category label`,
      defaultTimeframeText: "Last 12 months",
      precalculatedStats: chartLabelStats.value,
      volumes: chartVolumeStats.value,
      defaultField: "growth",
      fields: [{ key: "growth", value: "Growth" }],
      col: 4,
      level: "category",
    },
    {
      id: 6,
      type: WidgetType.TimelineChart,
      name: "Label timeline",
      timelineData: labelVolumesForlabels.value,
      defaultField: "",
      fields: [],
      processing: isLoadingLabelVolumes.value,
      col: 4,
    },
    {
      id: 3,
      type: WidgetType.BarChart,
      name: "Trending by keyword",
      defaultTimeframeText: "Latest",
      precalculatedStats: oldKwStats,
      volumes: oldKwStatsMap,
      defaultField: "trending",
      fields: [{ key: "trending", value: "Trending (% of last 12 months peak)" }],
      hide: oldKwStats.length === 0,
      col: 4,
      level: "keyword",
    },
    {
      id: 6,
      type: WidgetType.BarChart,
      name: "Volume by keyword",
      defaultTimeframeText: "Last 12 months",
      precalculatedStats: oldKwStats,
      volumes: oldKwStatsMap,
      defaultField: "volume",
      fields: [{ key: "volume", value: "Search volume" }],
      hide: oldKwStats.length === 0,
      col: 4,
      level: "keyword",
    },
    {
      id: 4,
      type: WidgetType.BarChart,
      name: "Growth by keyword",
      defaultTimeframeText: "Last 12 months",
      precalculatedStats: oldKwStats,
      volumes: oldKwStatsMap,
      defaultField: "growth",
      fields: [{ key: "growth", value: "Growth" }],
      hide: oldKwStats.length === 0,
      col: 4,
      level: "keyword",
    },
    {
      id: 5,
      type: WidgetType.BarChart,
      precalculatedStats: newKwStats,
      defaultField: "lastOneMonthVolume",
      fields: [
        { key: "volume", value: "Average monthly volume" },
        { key: "lastOneMonthVolume", value: "Last month's volume" },
      ],
      hide: newKwStats.length === 0,
      name: "New keywords",
      col: 6,
      level: "keyword",
    },
  ];
});
const filteredWidgets = computed(() => {
  return widgets.value.filter((widget: any) => !widget.hide);
});
let prevProps: any = {};
const processLabelStats = () => {
  const kwIds = filteredIds2.value;
  if (prevProps?.labels === labelsInFiltered.value && prevProps?.kwIds === kwIds) {
    return;
  }
  prevProps = {
    labels: labelsInFiltered.value,
    kwIds,
  };
  if (filteredKeywords.value?.length > 0) {
    if (labelsInFiltered.value?.length > 0) {
      store.dispatch("analysis/fetchAnalysisLabelStats", {
        analysisId: analysisId.value,
        kwIds: filteredIds2.value,
        labels: labelsInFiltered.value,
      });
    }

    const startTask = () => {
      const data = {
        keywords: JSON.parse(
          JSON.stringify(
            filteredKeywords.value.map(
              ({ id, volumes, volumes_from, keyword, labels, trending, is_new }) => ({
                id,
                volumes,
                volumes_from,
                keyword,
                labels,
                trending,
                is_new,
              })
            )
          )
        ),
        labels: Array.from(labelsInFiltered.value),
      };
      if (data.keywords) {
        startWorker(data, (result: any) => {
          labelStats.value = result.labelStats;
          keywordStats.value = result.keywordStats;
          volumes.value = result.volumes;
          dataDateRange.value = {
            firstYear: result.firstYear,
            firstMonth: result.firstMonth,
            latestYear: result.latestYear,
            latestMonth: result.latestMonth,
          };
          localLoading.value = false;
          processing.value = false;
        });
      } else {
        labelStats.value = [];
        keywordStats.value = [];
        localLoading.value = false;
        processing.value = false;
      }
    };
    setTimeout(startTask, 0);
  } else {
    labelStats.value = [];
    keywordStats.value = [];
    volumes.value = {};
    localLoading.value = false;
    processing.value = false;
  }
};
const debouncedProcessLabelStats = debounce(processLabelStats, 200);

const filterId = ref(route.params.id ? parseInt(route.params.id as string) : undefined);
watch(recommendedLevel, () => {
  if (!overrideRecommendedLevel.value && recommendedLevel.value && recommendedLevel.value !== level.value) {
    level.value = recommendedLevel.value;
    if (currentLevel.value !== level.value) {
      store.dispatch("analysis/switchAnalysisFilterLevel", {
        filterId: analysisId.value,
        viewId: viewId.value,
        level: level.value,
      });
    }
  }
});

const changeLevel = async (newLevel: number) => {
  overrideRecommendedLevel.value = true;
  if (level.value !== newLevel) {
    level.value = newLevel;
    processing.value = true;
    setTimeout(() => {
      store.dispatch("analysis/switchAnalysisFilterLevel", {
        filterId: analysisId.value,
        viewId: viewId.value,
        level: level.value,
      });
    }, 0);
  }
};
const resetLevel = async () => {
  overrideRecommendedLevel.value = false;
  level.value = recommendedLevel.value ?? 1;
  processing.value = true;
  setTimeout(() => {
    store.dispatch("analysis/switchAnalysisFilterLevel", {
      filterId: analysisId.value,
      viewId: viewId.value,
      level: level.value,
    });
  }, 0);
};
const fetchPredictions = () => {
  store.dispatch("analysis/fetchAnalysisLabelStats", {
    analysisId: analysisId.value,
    kwIds: filteredIds2.value,
    labels: labelsInFiltered.value,
    predictions: true,
  });
};
const onLabelSelect = (label: string) => {
  store.dispatch("analysis/addLabelFilter", { type: "include", label });
};
const onLabelUnselect = (label: string) => {
  store.dispatch("analysis/removeLabelFilter", label);
};
watch(
  viewId,
  (curr: number | undefined, old: number | undefined) => {
    if (viewId.value && curr !== old) {
      filterHasNoView.value = false;
      store.dispatch("selectView", {
        filterId: analysisId.value,
        viewId: viewId.value,
        labels: labelsInParams.value,
      });
    } else {
      // Filter doesn't have a view. Show information for the user guiding to create a view
      filterHasNoView.value = true;
    }
  },
  { immediate: true }
);
</script>
