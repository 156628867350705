<template>
  <div @mousemove="setPos" @mouseleave="unsetPos">
    <span class="kw">
      <strong ref="rowz" class="hover-white"
        >{{ fieldValue }}
        <span style="font-weight: 400">{{
          variants?.length > 0 ? `(+${variants.length} variants)` : ""
        }}</span></strong
      >
      <span v-if="variants?.length > 0 && left > 0" class="tooltip" :style="`top:${top}px;left:${left}px`">
        <ul>
          <li :key="variant" v-for="variant in variants">{{ variant }}</li>
        </ul>
      </span>
    </span>
  </div>
</template>
<style scoped>
.tooltip {
  position: fixed;
  text-align: left;
  margin-top: -20px;
  margin-left: 20px;
  padding: 12px 24px;
  z-index: 999;
  background-color: rgb(var(--v-theme-mairePink));
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.3)) !important;
  border-radius: 10px;
  color: white;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "KeywordCell",
  props: {
    fieldValue: [Number, String],
    field: Object,
    item: Object,
  },
  data: () => ({
    left: 0,
    top: 0,
  }),
  methods: {
    setPos: function (ev: any) {
      this.left = ev.clientX;
      this.top = ev.clientY;
    },
    unsetPos: function () {
      this.left = 0;
      this.top = 0;
    },
  },
  computed: {
    pos() {
      return {
        x: this.left,
        y: this.top,
      };
    },
    variants() {
      return this.item?.close_variants;
    },
  },
});
</script>
