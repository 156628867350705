<template>
  <Transition>
    <div class="slider">
      <v-range-slider
        v-model="slider"
        color="#14CCC9"
        track-size="2"
        :max="sliderState.rangeMax"
        :min="0"
        :step="step"
      >
        <template v-slot:prepend>
          <v-text-field
            v-model="slider[0]"
            hide-details
            type="number"
            class="slider-input slider-min"
            variant="outlined"
            density="compact"
            style="width: 80px"
          ></v-text-field>
        </template>
        <template v-slot:append>
          <v-text-field
            v-model="slider[1]"
            hide-details
            type="number"
            class="slider-input slider-min"
            variant="outlined"
            style="width: 80px"
            density="compact"
          ></v-text-field>
        </template>
      </v-range-slider>
    </div>
  </Transition>
</template>

<style scoped>
.slider {
  width: 100%;
}
</style>
<script lang="ts">
import { RangeSliderState } from "@/store/modules/analysis/types";
import { defineComponent } from "vue";
import { PropType } from "vue";

export default defineComponent({
  name: "RangeSlider",
  emits: ["change"],
  props: {
    sliderState: { type: Object as PropType<RangeSliderState>, required: true },
  },
  watch: {
    slider: {
      handler: "onValueChange",
      deep: true,
    },
  },
  data: () => ({
    slider: [0, Infinity],
  }),
  mounted() {
    if (this.sliderState) {
      this.slider[0] = this.sliderState.min || 0;
      this.slider[1] = this.sliderState.max || this.sliderState.rangeMax;
    }
  },
  computed: {
    step() {
      if (this.sliderState?.rangeMax && this.sliderState?.rangeMax < 150) {
        return 1;
      }
      return Math.floor(this.sliderState?.rangeMax / 1000) || 20;
    },
  },
  methods: {
    onValueChange(value: any) {
      // Emit event with the new value
      this.$emit("change", value);
    },
  },
});
</script>
