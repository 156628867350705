import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`chart ${_ctx.loading ? 'loading' : ''}`),
    style: {"position":"relative","height":"100%"}
  }, [
    _createVNode(_component_apexchart, {
      height: "400",
      ref: "chart",
      options: _ctx.options,
      series: _ctx.series,
      onLegendClick: _ctx.handleClick
    }, null, 8, ["options", "series", "onLegendClick"])
  ], 2)), [
    [_vShow, _ctx.options]
  ])
}