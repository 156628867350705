<template>
  <div class="label_cell inputs">
    <div
      @click="(e) => searchLabel(label.text, e)"
      v-for="label in fieldValue"
      :key="label.text"
      class="category-input-field inline"
    >
      <v-chip
        :style="{ 'background-color': getLabelColor(label.text), color: 'white' }"
        v-if="true"
        class="label"
      >
        <span class="text-truncate label-content kw-label">
          {{ label.text }}
          <v-tooltip top activator="parent" location="top">{{ label.text }}</v-tooltip>
        </span>

        <template #close>
          <v-icon icon="mdi:mdi-close-circle" @click.stop="() => openModal(label)" />
        </template>
      </v-chip>
    </div>
    <v-icon class="preventClick plusIcon centeredIcon" v-if="!creatingNew" @click="creatingNew = true"
      >fas fa-plus</v-icon
    >
    <v-autocomplete
      v-if="creatingNew"
      variant="outlined"
      v-model="newLabel"
      :items="analysisLabels"
      item-value="text"
      item-title="text"
      autofocus
      class="category-input-field preventClick labelInput"
      density="compact"
      :hide-details="true"
      @update:modelValue="saveLabel"
      @keydown.enter="(e: any) => saveLabel(e.target?.value)"
    />
  </div>
  <MaireDialog
    :fn="removeLabelFromKeyword"
    :title="`Remove ${label?.text}`"
    :description="`Do you want to remove this label from selected keywords, or just remove it from the keyword: ${item?.keyword}? Note that removing a label from a keyword applies across all analyses where it's used.`"
    :open="modalOpen"
    :onClose="closeModal"
    hidebuttons
  >
    <v-btn @click="modalOpen = false" class="plain-btn inline"> Cancel </v-btn>
    <v-btn @click="removeLabelFromSelected" class="plain-btn inline danger"> Remove from selected</v-btn>
    <v-btn @click="removeLabelFromKeyword" class="plain-btn inline inverse"> Remove from this </v-btn>
  </MaireDialog>
</template>
<style scoped>
.plusIcon {
  color: rgba(var(--v-theme-mairePurple)) !important;
  border-radius: 25px;
  margin: 5px;
  padding: 0px;
  text-align: center;
  outline: 2px solid rgba(var(--v-theme-mairePurple));
}
.labelInput {
  margin-top: 10px;
}

.label span {
  max-width: 50px;
}
.label {
  cursor: pointer;
}
.danger {
  background-color: red !important;
  color: white !important;
  border-style: none;
}
.inverse {
  background-color: rgba(var(--v-theme-mairePurpleSecondary)) !important;
}

.danger:hover {
  border-style: none;
  color: white !important;
}

.label {
  margin: 2px;
  /* white-space: wrap;
  max-width: 5; */
}

p {
  color: white;
  font-size: 12px;
}
</style>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { getLabelColor } from "@/components/helpers/helpers";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import { ViewLabel } from "@/store/modules/labels/types";
import { Keyword } from "@/store/modules/keywords/types";
import { useStore } from "@/store"

export default defineComponent({
  name: "LabelCell",
  data: () => ({
    modalOpen: false,
    label: undefined as ViewLabel | undefined,
    creatingNew: false,
    newLabel: "",
  }),
  setup() {
    const store = useStore();
    return { store };
  },
  props: {
    fieldValue: Array as PropType<ViewLabel[]>,
    field: Object,
    item: Object,
    viewId: { type: Number, required: false },
    selectedItems: Array as PropType<Keyword[]>,
  },

  computed: {
    currentAnalysis() {
      return this.store.state.analysis.currentAnalysis;
    },
    analysisLabels() {
      if (!this.currentAnalysis?.id) {
        return Object.values(this.store.state.labels.labels) ?? [];
      }
      return this.store.state.labels.analysisLabels?.[this.currentAnalysis?.id] ?? [];
    },
  },
  methods: {
    saveLabel(label?: string) {
      this.creatingNew = false;
      const labelToCreate = label || this.newLabel;
      this.newLabel = "";
      if (!labelToCreate || !this.item?.id) {
        return;
      }
      this.store.dispatch("keywords/addLabelToKeywords", {
        label: labelToCreate,
        kwIds: [this.item?.id],
        filter: this.currentAnalysis,
        viewId: this.viewId,
      });
    },
    closeModal() {
      this.modalOpen = false;
    },
    openModal(label: ViewLabel) {
      this.modalOpen = true;
      this.label = label;
    },
    removeLabelFromKeyword() {
      this.closeModal();
      this.store.dispatch("keywords/removeLabelFromKeywords", {
        labels: [this.label],
        kwIds: [this.item?.id],
      });
    },
    removeLabelFromSelected() {
      this.closeModal();
      if (!this.selectedItems?.length || !this.label) return;

      this.store.dispatch("keywords/removeLabelFromKeywords", {
        labels: [this.label],
        kwIds: this.selectedItems.map((kw) => kw.id),
      });
    },
    searchLabel(label: string, e: any) {
      if (e.target?.className?.includes("fa-times-circle")) {
        return;
      }
      this.store.dispatch("analysis/addLabelFilter", { label: label });
    },
    getLabelColor,
  },
  components: { MaireDialog },
});
</script>
