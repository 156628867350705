<template>
  <span v-if="!loading && fieldValue">{{ parseInt(fieldValue) }} </span>
  <span v-if="!loading && !fieldValue"
    ><v-icon style="color: purple" @click="refreshSerp">mdi:mdi-refresh</v-icon></span
  >
  <v-progress-circular color="purple" size="20" indeterminate v-if="loading" />
</template>

<script lang="ts">
import { Keyword } from "@/store/modules/keywords/types";
import { PropType, defineComponent } from "vue";
import { useStore } from "@/store";
export default defineComponent({
  name: "PositionCell",
  props: {
    fieldValue: [String, Number],
    field: Object,
    item: Object,
    viewId: { type: Number, required: false },
    selectedItems: Array as PropType<Keyword[]>,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    refreshSerp() {
      if (this.item?.id) {
        this.store.dispatch("keywords/getKeywordDetails", { kw_id: this.item.id, loadedInTable: true });
      }
    },
  },
  computed: {
    loading() {
      return this.item?.loading;
    },
  },
});
</script>
