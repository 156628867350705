import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72369935"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "layer"
}
const _hoisted_2 = {
  key: 0,
  class: "dialogPopup inline"
}
const _hoisted_3 = { class: "white" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "content maire-scrollbar" }
const _hoisted_6 = {
  key: 1,
  class: "buttons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.open)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.open)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
              (_ctx.description)
                ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.description), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              (!_ctx.hidebuttons)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (!_ctx.hideCancel)
                      ? (_openBlock(), _createBlock(_component_v_btn, {
                          key: 0,
                          onClick: _ctx.onClose,
                          class: _normalizeClass(`plain-btn inline`)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.cancelText), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_btn, {
                      onClick: _ctx.fn,
                      class: _normalizeClass(`plain-btn inverse inline ${_ctx.confirmText === 'Delete' ? 'error' : ''}`),
                      disabled: _ctx.confirmDisabled,
                      color: `${_ctx.confirmText === 'Delete' ? 'error' : ''}`
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.confirmText), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick", "class", "disabled", "color"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}