import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33b92fdc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kw" }
const _hoisted_2 = {
  ref: "rowz",
  class: "hover-white"
}
const _hoisted_3 = { style: {"font-weight":"400"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onMousemove: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setPos && _ctx.setPos(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.unsetPos && _ctx.unsetPos(...args)))
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createElementVNode("strong", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.fieldValue) + " ", 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.variants?.length > 0 ? `(+${_ctx.variants.length} variants)` : ""), 1)
      ], 512),
      (_ctx.variants?.length > 0 && _ctx.left > 0)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "tooltip",
            style: _normalizeStyle(`top:${_ctx.top}px;left:${_ctx.left}px`)
          }, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.variants, (variant) => {
                return (_openBlock(), _createElementBlock("li", { key: variant }, _toDisplayString(variant), 1))
              }), 128))
            ])
          ], 4))
        : _createCommentVNode("", true)
    ])
  ], 32))
}