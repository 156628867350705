<template>
  <div class="button">
    <MaireButton
      inverse
      :compact="true"
      :text="getText"
      :subtext="getComparisonText"
      @click="handleButtonClick"
    />
  </div>
  <div v-if="datePickerOpen" class="date-picker maire-drop-shadow paper-background">
    <div class="presets" v-if="!calendarOpen">
      <div v-for="preset in presets" :key="preset.key" class="xxs">
        <div
          class="custom-radio"
          :class="{ 'is-checked': presetSelected === preset }"
          @click="handlePresetClick(preset)"
        >
          <div class="radio-dot"></div>
          <label>{{ preset.value }}</label>
        </div>
      </div>
    </div>
    <MaireButton
      :compact="true"
      v-if="!forceSingleMonth"
      style="margin-bottom: 12px"
      @click="toggleComparisonType"
      :text="`${
        comparisonType === 'previousPeriod' ? 'compare to previous year' : 'compare to previous period'
      }`"
    />
    <MaireButton
      v-if="!calendarOpen"
      :compact="true"
      @click="calendarOpen = !calendarOpen"
      :text="`Set custom dates`"
    />
    <div v-if="calendarOpen" class="month-picker">
      <span class="year-selector">
        <v-icon @click="year--">fas fa-arrow-left</v-icon>
        <span>{{ year }}</span>
        <v-icon @click="year++">fas fa-arrow-right</v-icon>
      </span>
      <v-row dense>
        <v-col v-for="month in months" :key="month.key" cols="4">
          <div
            :class="`month-button
          ${isSelected({ year: year, month: month.key }) ? 'selected' : ''}
          ${isComparisonSelected({ year: year, month: month.key }) ? 'comparison' : ''}
          ${isOutOfBounds({ year: year, month: month.key }) ? 'disabled' : ''}`"
            @click="!isOutOfBounds({ year: year, month: month.key }) && handleMonthClick(month.key)"
          >
            <span class="xxs">{{ month.name }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<style scoped>
.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 12px;
}
.custom-radio label {
  cursor: pointer;
  color: rgba(0, 0, 0, 0, 0.03);
}

.radio-dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ddd;
  margin-right: 10px;
  position: relative;
}

.is-checked .radio-dot {
  border-color: rgb(var(--v-theme-mairePurple));
}

.is-checked .radio-dot::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  background-color: rgb(var(--v-theme-mairePurple));
  border-radius: 50%;
}

/** center the date picker to middle of parent */
.date-picker {
  position: absolute;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, 0%);

  width: 300px;
}
.month-picker {
  width: 100%;
}
.button {
  top: 12px;
  right: 12px;
  max-width: 150px;
}
.month-button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.year-selector {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
}
.year-selector i {
  cursor: pointer;
  font-size: 16px;
}
.month-button {
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.03);
  padding: 4px 12px;
  cursor: pointer;
  user-select: none;
}
.month-button.comparison {
  background: rgb(var(--v-theme-maireTeal));
  color: white;
}
.month-button.selected {
  background: rgb(var(--v-theme-mairePurple));
  color: white;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import MaireButton from "../ButtonBlock/MaireButton.vue";

export interface TimeRange {
  start: { year: number; month: number };
  end: { year: number; month: number };
}

export interface TimeRangeIncomplete {
  start: { year?: number; month?: number };
  end: { year?: number; month?: number };
}

export default defineComponent({
  props: {
    start: {
      type: Object,
      required: false,
      default: () => ({ year: undefined, month: undefined }),
    },
    end: {
      type: Object,
      required: false,
      default: () => ({ year: undefined, month: undefined }),
    },
    forceSingleMonth: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultTimeframeText: {
      type: String,
      required: false,
      default: "Time period",
    },
  },
  emits: ["save"],
  data: () => ({
    calendarOpen: false,
    presetSelected: undefined as { key: string; value: string; months: number } | undefined,
    datePickerOpen: false,
    year: new Date().getFullYear(),
    comparisonType: "previousYear" as "previousPeriod" | "previousYear",
    saveTimer: 0 as any,
    presets: [
      { key: "YTDVolume", value: "Year to date" },
      { key: "last12MonthsVolume", value: "Last 12 months", months: 11 },
      { key: "last6MonthsVolume", value: "Last 6 months", months: 5 },
      { key: "last3MonthsVolume", value: "Last 3 months", months: 2 },
      { key: "lastOneMonthVolume", value: "Last 1 month", months: 0 },
    ] as { key: string; value: string; months: number }[],
    months: [
      { key: 1, name: "January" },
      { key: 2, name: "February" },
      { key: 3, name: "March" },
      { key: 4, name: "April" },
      { key: 5, name: "May" },
      { key: 6, name: "June" },
      { key: 7, name: "July" },
      { key: 8, name: "August" },
      { key: 9, name: "September" },
      { key: 10, name: "October" },
      { key: 11, name: "November" },
      { key: 12, name: "December" },
    ],
    timerange: {
      start: { year: undefined, month: undefined },
      end: { year: undefined, month: undefined },
    } as TimeRangeIncomplete | undefined,
    comparison: undefined as TimeRangeIncomplete | undefined,
  }),
  mounted() {
    this.year = this.end.year || new Date().getFullYear();
    const preset = this.presets.find((preset) => preset.value === this.defaultTimeframeText);
    if(preset) {
      this.presetSelected = preset;
      const { timeframe, comparison } = this.timeFrameFromSelectedPreset(this.comparisonType);
      this.timerange = timeframe;
      this.comparison = comparison;
    }
  },
  computed: {
    selected() {
      if (!this.timerange) return [];
      return this.getYearMonthCombinationsForRange(this.timerange);
    },
    selectedComparison() {
      if (!this.comparison) return [];
      return this.getYearMonthCombinationsForRange(this.comparison);
    },
    getText() {
      if (!this.presetSelected && !this.timerange?.start?.year && !this.timerange?.end?.year) {
        return this.defaultTimeframeText;
      }
      if (this.timerange) {
        return this.getTextualTimerange(this.timerange);
      } else {
        return "Time period";
      }
    },
    getComparisonText() {
      if (!this.comparison) {
        return undefined;
      } else {
        if(this.presetSelected && this.timerange) {
          const comparisonTimeRangeAsText = this.getTextualTimerange(this.comparison, true);
          const timerangeAsText = this.getTextualTimerange(this.timerange, true);
          return `(${comparisonTimeRangeAsText} / ${timerangeAsText})`;
        }
        const comparisonTimeRangeAsText = this.getTextualTimerange(this.comparison);
        return `(${comparisonTimeRangeAsText})`;
      }
    },
  },
  watch: {
    forceSingleMonth() {
      this.calendarOpen = this.forceSingleMonth;
      this.comparison = undefined;
      if (this.forceSingleMonth) {
        this.timerange = {
          start: { year: this.timerange?.end?.year, month: this.timerange?.end?.month },
          end: { year: this.timerange?.end?.year, month: this.timerange?.end?.month },
        };
        this.handleSave();
      }
    },
  },
  methods: {
    getYearMonthCombinationsForRange(range: TimeRangeIncomplete) {
      // Return an empty array if the start date is undefined.
      if (range?.start.year === undefined || range?.start.month === undefined) return [];
      if (range?.end.year === undefined || range?.end.month === undefined)
        return [{ year: range?.start.year, month: range?.start.month }];
      let year = range?.start.year;
      let month = range?.start.month;
      const selections = [];

      // Keep iterating until we've reached the end date.
      while (year < range?.end.year || (year === range?.end.year && month <= range?.end.month)) {
        // Add the current year and month to the selections.
        selections.push({ year: year, month: month });

        // Move to the next month.
        month++;

        // If it's the end of the year, move to the next year and reset the month to 0 (January).
        if (month > 12) {
          month = 1;
          year++;
        }
      }
      return selections;
    },
    toggleComparisonType() {
      this.comparisonType = this.comparisonType === "previousPeriod" ? "previousYear" : "previousPeriod";
      if (this.presetSelected) {
        const { timeframe, comparison } = this.timeFrameFromSelectedPreset(this.comparisonType);
        this.timerange = timeframe;
        this.comparison = comparison;
        if (timeframe) {
          this.$emit("save", { timeframe, comparison });
        }
      } else {
        this.comparison = this.calculateComparisonPeriodForRange(
          this.timerange as TimeRange,
          this.comparisonType
        );
        if (this.timerange?.start?.month && this.timerange?.end?.month && this.comparison) {
          this.$emit("save", { timeframe: this.timerange, comparison: this.comparison });
        }
      }
    },
    handleButtonClick() {
      this.datePickerOpen = !this.datePickerOpen;
      if (this.forceSingleMonth) {
        this.calendarOpen = true;
      }
    },
    getTextualTimerange(timerange: TimeRangeIncomplete, forceDate?: boolean) {
      if (!timerange?.start.year && !timerange?.end.year) return "Time period";
      if (timerange?.start.month === undefined && timerange?.end.month === undefined) return "Time period";
      if (this.presetSelected && !forceDate) return this.presetSelected.value;
      const startMonthString = timerange?.start.month !== undefined ? `${timerange?.start.month}` : "";
      const startYearString =
        timerange?.start.year !== undefined ? `${timerange?.start.year.toString().slice(2)}` : "";
      const startString = `${startMonthString}'${startYearString}`;
      const endMonthString = timerange?.end.month !== undefined ? `${timerange?.end.month}` : "";
      const endYearString =
        timerange?.end.year !== undefined ? `'${timerange?.end.year.toString().slice(2)}` : "";
      const endString = `${endMonthString}${endYearString}`;
      return `${startString} - ${endString}`;
    },
    calculateComparisonPeriodForRange(range: TimeRange, comparisonType: "previousPeriod" | "previousYear") {
      const monthsBetween = (range.end.year - range.start.year) * 12 + range.end.month - range.start.month;
      let comparison = undefined;
      const startDate = new Date(range.start.year, range.start.month - 1, 15);
      const endDate = new Date(range.end.year, range.end.month - 1, 15);
      if (comparisonType === "previousYear") {
        const comparisonStartDate = new Date(startDate);
        const comparisonEndDate = new Date(endDate);
        comparisonStartDate.setFullYear(comparisonStartDate.getFullYear() - 1);
        comparisonEndDate.setFullYear(comparisonEndDate.getFullYear() - 1);
        comparison = {
          start: { year: comparisonStartDate.getFullYear(), month: comparisonStartDate.getMonth() + 1 },
          end: { year: comparisonEndDate.getFullYear(), month: comparisonEndDate.getMonth() + 1 },
        };
      } else if (comparisonType === "previousPeriod") {
        const comparisonEndDate = new Date(startDate);
        comparisonEndDate.setMonth(comparisonEndDate.getMonth() - 1);
        const comparisonStartDate = new Date(
          comparisonEndDate.getFullYear(),
          comparisonEndDate.getMonth() - monthsBetween,
          15
        );
        comparison = {
          start: { year: comparisonStartDate.getFullYear(), month: comparisonStartDate.getMonth() + 1 },
          end: { year: comparisonEndDate.getFullYear(), month: comparisonEndDate.getMonth() + 1 },
        };
      }
      return comparison;
    },
    isSelected({ year, month }: { year: number; month: number }) {
      return this.selected.some((selection) => selection.year === year && selection.month === month);
    },
    isComparisonSelected({ year, month }: { year: number; month: number }) {
      return this.selectedComparison.some(
        (selection) => selection.year === year && selection.month === month
      );
    },
    isOutOfBounds({ year, month }: { year: number; month: number }) {
      if (this.end.year !== undefined && this.end.month !== undefined) {
        if (year > this.end.year) return true;
        if (year === this.end.year && month > this.end.month) return true;
      }
      if (this.start.year !== undefined && this.start.month !== undefined) {
        if (year < this.start.year) return true;
        if (year === this.start.year && month < this.start.month) return true;
      }
      return false;
    },
    handleSave() {
      this.calendarOpen = false;
      this.datePickerOpen = false;
      this.presetSelected = undefined;
      const timeframe = {
        start: { year: this.timerange?.start.year, month: this.timerange?.start.month },
        end: { year: this.timerange?.end.year, month: this.timerange?.end.month },
      };
      this.$emit("save", {
        timeframe,
        comparison: this.comparison,
      });
    },
    timeFrameFromSelectedPreset(comparisonType: "previousPeriod" | "previousYear"): {
      timeframe: undefined | { start: { year: number; month: number }; end: { year: number; month: number } };
      comparison:
        | undefined
        | { start: { year: number; month: number }; end: { year: number; month: number } };
    } {
      if (!this.end.year || !this.end.month || !this.presetSelected) {
        return { timeframe: undefined, comparison: undefined };
      }
      const endDate = new Date(this.end.year, this.end.month - 1, 15); // month is 0 indexed
      let monthsToSubtract;
      if (this.presetSelected?.key === "YTDVolume") {
        const startOfYear = new Date(endDate.getFullYear(), 0, 15);
        monthsToSubtract = endDate.getMonth() - startOfYear.getMonth();
      } else {
        monthsToSubtract = this.presetSelected.months || 0;
      }
      const startDate = new Date(endDate.getFullYear(), endDate.getMonth() - monthsToSubtract);
      const timeframe = {
        start: { year: startDate.getFullYear(), month: startDate.getMonth() + 1 }, // month is 0 indexed, so add 1
        end: { year: endDate.getFullYear(), month: endDate.getMonth() + 1 },
      };
      const comparison = this.calculateComparisonPeriodForRange(timeframe, comparisonType);
      return { timeframe, comparison };
    },
    handlePresetClick(preset: { key: string; value: string; months: number }) {
      this.calendarOpen = false;
      this.datePickerOpen = false;
      if(this.presetSelected?.key === preset.key) {
        return
      }
      this.presetSelected = preset;
      const { timeframe, comparison } = this.timeFrameFromSelectedPreset(this.comparisonType);
      this.timerange = timeframe;
      this.comparison = comparison;
      if (timeframe) {
        this.$emit("save", { timeframe, comparison });
      }
    },
    handleMonthClick(month: number) {
      this.saveTimer && clearTimeout(this.saveTimer);
      this.saveTimer = 0;
      const delay = 1000;

      // If forceSingleMonth is true, set the start and end date to the same month.
      if (this.forceSingleMonth) {
        this.timerange = {
          start: { year: this.year, month: month },
          end: { year: this.year, month: month },
        };
        this.saveTimer = setTimeout(() => {
          this.handleSave();
        }, delay);
        return;
      }
      // If the start date is not set, set the clicked date as the start date.

      if (this.timerange?.start.year === undefined || this.timerange.start.month === undefined) {
        this.timerange = {
          start: { year: this.year, month: month },
          end: { year: undefined, month: undefined },
        };
        return;
      }
      // If the start date is set but the end date is not, set the clicked date as the end date.
      if (this.timerange?.end?.year === undefined || this.timerange?.end?.month === undefined) {
        // if the clicked date is before the start date, switch the start and end dates
        if (
          this.year < this.timerange.start.year ||
          (this.year === this.timerange.start.year && month < this.timerange.start.month)
        ) {
          this.timerange = {
            start: { year: this.year, month: month },
            end: { year: this.timerange?.start.year, month: this.timerange?.start.month },
          };
          this.saveTimer = setTimeout(() => {
            this.handleSave();
          }, delay);
          return;
        } else {
          this.timerange.end = { year: this.year, month: month };
          this.comparison = this.calculateComparisonPeriodForRange(
            this.timerange as TimeRange,
            this.comparisonType
          );
          this.saveTimer = setTimeout(() => {
            this.handleSave();
          }, delay);
          return;
        }
      }

      // if both start and end dates are set and the user clicks on the same start date, set that as the new end date
      if (this.timerange?.start.year === this.year && this.timerange?.start.month === month) {
        this.timerange.end = {
          year: this.year,
          month: month,
        };
        this.saveTimer = setTimeout(() => {
          this.handleSave();
        }, delay);
        return;
      }
      // otherwise reset the start date and set the clicked date as the new start date
      this.timerange = {
        start: { year: this.year, month: month },
        end: { year: undefined, month: undefined },
      };
    },
  },
  components: { MaireButton },
});
</script>
