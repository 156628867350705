<template>
  <div class="graphs paper-background maire-drop-shadow category-pie">
    <p v-if="chartName" class="label strong text-primary s chart-name">{{ chartName }}</p>
    <div v-if="stats?.length && stats.length > 0" style="height: 400px; padding-bottom: 30px">
      <div class="select-field" v-if="volumes" style="z-index: 12; padding: 12px">
        <MonthPicker
          @save="handleTimerangeChange"
          :end="{ year: dataDateRange?.latestYear, month: dataDateRange?.latestMonth }"
          :start="{ year: dataDateRange?.firstYear, month: dataDateRange?.firstMonth }"
          :defaultTimeframeText="defaultTimeframeText"
          :forceSingleMonth="field === 'trending'"
        />
      </div>
      <Transition name="slide">
        <div style="height: 100%" v-if="isPieChart">
          <PieChart
            :stats="stats"
            :postFix="postFixForFields(field)"
            :field="field"
            :loading="loading"
            :label="chartName"
            @select="onSelect"
            @unselect="onUnSelect"
          />
        </div>
      </Transition>
      <Transition name="slide">
        <div style="height: 100%" v-if="isBarChart && field">
          <BarChart
            :stats="stats"
            :id="id"
            :postFix="postFixForFields(field)"
            :field="field"
            :loading="loading"
            :label="chartName"
            @select="onSelect"
            @unselect="onUnSelect"
          />
        </div>
      </Transition>
      <div class="full maire-drop-shadow paper-background" v-if="isBarChart && field && fullscreen">
        <p v-if="chartName" class="label strong text-primary s chart-name">{{ chartName }}</p>
        <BarChart
          :stats="stats"
          :id="id"
          :postFix="postFixForFields(field)"
          :field="field"
          :loading="loading"
          :label="chartName"
          @select="onSelect"
          @unselect="onUnSelect"
        />
      </div>
    </div>
    <div v-else-if="timelineStats" style="height: 400px; padding-bottom: 30px">
      <div v-if="isTimelineChart" class="container-margin">
        <div v-if="loading || Object.keys(timelineStats)?.length > 0">
          <TimelineChart
            :stats="timelineStats"
            :loading="loading"
            @select="onSelect"
            @unselect="onUnSelect"
          />
          <div class="full maire-drop-shadow paper-background" v-if="fullscreen">
            <div class="close-modal-btn" @click="toggleFullscreen">
              <v-icon>fal fa-times-circle</v-icon>
            </div>
            <p v-if="chartName" class="label strong text-primary s chart-name">{{ chartName }}</p>
            <TimelineChart
              :stats="timelineStats"
              :loading="loading"
              @select="onSelect"
              @unselect="onUnSelect"
            />
          </div>
        </div>
        <div v-else>
          <p class="s center">No labels present in selected keywords</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="loading">
        <WaitAnimation message="Analyzing" />
      </div>
      <p v-else class="s center">No labels present in selected keywords</p>
    </div>
    <div class="center pie-buttons" style="width: 100%">
      <div
        v-if="!isTimelineChart"
        class="inline-block"
        style="display: inline-block; width: 150px; float: left; padding-right: 24px"
      >
        <maire-button :compact="true" @click="switchChartType" inverse :text="getSwitchText()" />
      </div>
      <div
        class="inline-block"
        style="display: inline-block; width: 150px; float: right; padding-right: 24px"
      >
        <maire-button
          v-if="isTimelineChart"
          :compact="true"
          @click="toggleFullscreen"
          inverse
          text="Full screen"
        />
      </div>
    </div>

    <div v-show="fullscreen" class="backdrop"></div>
  </div>
</template>
<style scoped>
.backdrop {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  z-index: 9998;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}
.full {
  position: fixed;
  width: 80%;
  margin: auto;
  left: 10%;
  top: 30%;
  max-height: 90%;
  z-index: 9999;
}
.close-modal-btn {
  color: rgb(var(--v-theme-mairePurple));
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 12px;
}
.chart-name {
  margin-right: 200px;
}
.slide-leave-active,
.slide-enter-active {
  transition: opacity 0.5s ease-in-out;
}
.slide-leave-active {
  height: 0px !important;
  opacity: 0;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}
.slide-leave-to {
  height: 0px !important;
}
.label {
  margin-bottom: 12px;
}
.graphs {
  padding-top: 24px;
  padding-right: 24px;
  /* padding-bottom: 70px; */
}
.drawer-content {
  display: none;
  margin-top: 24px;
}
.drawer-content.active {
  display: block;
}
.drawer-chevron {
  position: relative;
  cursor: pointer;
  float: right;
}
.drawer-bar {
  width: 100%;
  cursor: pointer;
}
.saved-analysis-name {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 24px;
  color: black;
}
.saved-filters-container {
  padding: 12px;
  width: 100%;
}
.select-field {
  z-index: 2;
  position: absolute;
  right: 12px;
  top: 0px;
  display: block;
}
.pie-buttons {
  position: absolute;
  bottom: 12px;
  width: 100%;
  padding-left: 0px;
}
.container-margin {
  margin-top: 12px;
  margin-bottom: 12px;
}
.save-analysis-button {
  position: absolute;
  right: 12px;
  top: 12px;
}
.category-bar {
  position: relative;
  height: 100%;
}

.category-pie {
  position: relative;
  height: 100%;
  margin-bottom: 36px;
}
.title-text {
  margin-bottom: 36px;
  padding-bottom: 20px !important;
  width: 70%;
  display: inline-block;
}
.search_container {
  width: 30%;
  float: right;
  position: relative;
  display: block;
}
.selected-label {
  box-shadow: 0px 0px 20px rgba(130, 0, 255, 0.2);
}
.unselected-label {
  filter: opacity(50%);
}
.category-pie h3 {
  max-height: 400px;
}

.custom-placeholer-color input::placeholder {
  color: rgb(0, 0, 136) !important;
  opacity: 1;
  font-size: 14px;
}
.loading {
  filter: blur(4px) brightness(100%);
}
</style>
<script lang="ts">
// import MaireSelect from "@/components/MaireSelect/MaireSelect.vue";
import PieChart from "@/components/PieChart/PieChart.vue";
import BarChart from "@/components/BarChart/BarChart.vue";
import WaitAnimation from "../WaitAnimation/WaitAnimation.vue";
import TimelineChart from "@/components/TimelineChart/TimelineChart.vue";
import { defineComponent } from "vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import MonthPicker, { TimeRange } from "@/components/MonthPicker/MonthPicker.vue";
import { WidgetType } from "@/store";
import { GrowthStat } from "@/composables/worker";

export default defineComponent({
  props: {
    precalculatedStats: {
      type: Array as () => GrowthStat[],
      required: false,
    },
    timelineStats: {
      type: Object,
      required: false,
    },
    volumes: {
      type: Object,
      required: false,
    },
    dataDateRange: {
      type: Object,
      required: false,
      properties: {
        latestYear: {
          type: Number,
          required: false,
        },
        latestMonth: {
          type: Number,
          required: false,
        },
        firstYear: {
          type: Number,
          required: false,
        },
        firstMonth: {
          type: Number,
          required: false,
        },
      },
    },
    onSelect: {
      type: Function,
      required: true,
      default: () => {
        console.log("onSelect not implemented");
      },
    },
    onUnSelect: {
      type: Function,
      required: true,
      default: () => {
        console.log("onUnSelect not implemented");
      },
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultField: {
      type: String,
      required: true,
    },
    fields: {
      type: Array as () => { key: string; value: string }[],
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    level: {
      type: String,
      required: true,
      default: "category",
    },
    name: {
      type: String,
      required: false,
    },
    defaultTimeframeText: {
      type: String,
      required: false,
    },
    initialType: {
      default: WidgetType?.BarChart,
      required: false,
    },
  },
  data: () => ({
    field: "",
    labelCountMap: {} as Record<string, number>,
    type: WidgetType.BarChart,
    selectedRange: {} as TimeRange,
    comparisonRange: {} as TimeRange,
    labelTotals: {} as Record<string, Record<string, number>>,
    labelVolumesForSelectedRange: {} as Record<string, Record<number, number[]>>,
    labelVolumesForComparisonRange: {} as Record<string, Record<number, number[]>>,
    fullscreen: false,
  }),
  computed: {
    chartName() {
      if (this.name) {
        return this.name;
      } else {
        return `${this.defaultField} by ${this.level}`;
      }
    },
    volumesForTimeRangeAndLabel() {
      return this.volumes;
    },
    stats(): GrowthStat[] {
      if (!this.selectedRange?.start?.year) {
        return this.precalculatedStats ?? [];
      }
      return Object.entries(this.labelTotals).map(([label, totals]) => {
        const { total, comparison, maxComparison } = totals;
        return {
          label,
          volume: total,
          growth: comparison > 0 ? (total / comparison - 1) * 100 : total > 0 ? 100 : 0,
          absoluteGrowth: total - comparison,
          trending: this.field === "trending" && maxComparison > 0 ? (total / maxComparison - 1) * 100 : 0,
          count: this.level === "category" ? this.labelCountMap?.[label] : 1,
        };
      });
    },
    isPieChart() {
      return this.type === WidgetType.PieChart;
    },
    isBarChart() {
      return this.type === WidgetType.BarChart;
    },
    isTimelineChart() {
      return this.type === WidgetType.TimelineChart;
    },
  },
  watch: {
    volumes() {
      this.recalculateStats();
    },
    precalculatedStats() {
      if (this.level === "category") {
        this.labelCountMap = Object.fromEntries(
          (this.precalculatedStats || []).map((stat) => [stat.label, stat.count ?? 1])
        );
      }
    },
  },
  mounted() {
    if (this.initialType) {
      this.type = this.initialType;
    }
    this.field = this.defaultField;
  },
  methods: {
    toggleFullscreen() {
      // attach listener for escape key
      if (!this.fullscreen) {
        document.addEventListener(
          "keydown",
          (event: KeyboardEvent) => event.key === "Escape" && (this.fullscreen = false)
        );
      } else {
        document.removeEventListener(
          "keydown",
          (event: KeyboardEvent) => event.key === "Escape" && (this.fullscreen = false)
        );
      }
      this.fullscreen = !this.fullscreen;
    },
    yearMonthKeysInRange(range: TimeRange) {
      const keys = [];
      for (let year = range.start.year; year <= range.end.year; year++) {
        // months are also part of the range
        const startMonth = year === range.start.year ? range.start.month : 1;
        const endMonth = year === range.end.year ? range.end.month : 12;
        for (let month = startMonth; month <= endMonth; month++) {
          keys.push({ year, month });
        }
      }
      return keys;
    },
    recalculateStats() {
      if (!this.volumes) return;
      if (this.loading) return; // do not recalculate stats while loading, they will change
      if (!this.selectedRange.start?.month || !this.selectedRange.end?.month) return;
      const labelTotals = {} as Record<string, Record<string, number>>;
      const monthsToCheck = this.yearMonthKeysInRange(this.selectedRange);
      const comparisonMonthsToCheck = this.yearMonthKeysInRange(this.comparisonRange);
      Object.entries(this.volumes).forEach(([label, volumes]) => {
        if (!volumes) return;
        labelTotals[label] = { total: 0, comparison: 0, maxComparison: 0 };
        for (const { year, month } of monthsToCheck) {
          labelTotals[label].total += volumes[year]?.[month - 1] ?? 0;
        }
        for (const { year, month } of comparisonMonthsToCheck) {
          const compVolume = volumes[year]?.[month - 1] ?? 0;
          labelTotals[label].comparison += compVolume;
          if (this.field === "trending" && compVolume > labelTotals[label].maxComparison) {
            labelTotals[label].maxComparison = compVolume;
          }
        }
      });
      this.labelTotals = labelTotals;
    },

    handleTimerangeChange({ timeframe, comparison }: { timeframe: TimeRange; comparison: TimeRange }) {
      this.selectedRange = timeframe;
      if (this.field === "trending") {
        const endDate = new Date(timeframe.start.year, timeframe.start.month - 1, 15);
        const startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 12);
        this.comparisonRange = {
          start: { year: startDate.getFullYear(), month: startDate.getMonth() },
          end: { year: endDate.getFullYear(), month: endDate.getMonth() },
        };
      } else {
        this.comparisonRange = comparison;
      }
      this.recalculateStats();
    },
    switchChartType() {
      this.type = this.type === WidgetType.PieChart ? WidgetType.BarChart : WidgetType.PieChart;
    },
    getSwitchText() {
      return this.type === WidgetType.PieChart ? "Show bar chart" : "Show pie chart";
    },
    postFixForFields(field: string) {
      const fieldsPostFix = {
        potential: "€",
        volume: "",
      };
      if ((field?.includes("growth") && !field.includes("Absolute")) || field === "trending") {
        return "%";
      }
      return fieldsPostFix[field as keyof typeof fieldsPostFix];
    },
  },
  components: {
    BarChart,
    PieChart,
    TimelineChart,
    MaireButton,
    WaitAnimation,
    MonthPicker,
  },
});
</script>
