<template>
  <div :class="`chart ${loading ? 'loading' : ''}`" v-show="options" style="position: relative; height: 100%">
    <apexchart height="400" ref="chart" :options="options" :series="series" @legendClick="handleClick">
    </apexchart>
  </div>
</template>
<style scoped>
.waiting_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  padding: 13%;
}
span {
  background-color: rgb(255, 176, 176);
  margin-top: 70px;
  padding: 10px;
}
.loading {
  filter: blur(4px) brightness(80%);
}
.chart {
  transition: filter 0.5s ease-in-out;
}
</style>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { getLabelColor } from "../helpers/helpers";
import { VueApexChartsComponent } from "vue3-apexcharts";
export interface VolumeDataPoint {
  date_from: Date;
  monthly_searches: number;
  is_forecast?: boolean;
}
export default defineComponent({
  name: "TimeLineChart",
  emits: ["unselect"],
  props: {
    stats: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    direction: "horizontal",
    rendered: true,
    chart: ref(null) as any,
    series: [] as any[],
    options: null as any,
    margin: {
      left: 0,
      top: 20,
      right: 20,
      bottom: 0,
    },
    publicPath: process.env.BASE_URL,
  }),
  watch: {
    stats() {
      if (this.stats) {
        const series = this.calculateSeries();
        const annotations = this.createAnnotationOptions(series);
        const renderAnnotations = !!annotations?.xaxis?.length;
        if (!this.loading && this.chart) {
          this.chart.chart.updateOptions(
            {
              series: series,
              ...(renderAnnotations ? { annotations } : {}),
            },
            false,
            true,
            false
          );
        }
      }
    },
  },
  mounted() {
    this.series = this.calculateSeries();
    this.options = this.calculateOptions();
    const el = this.$refs?.chart as VueApexChartsComponent;
    this.chart = el;
  },
  methods: {
    handleClick(_event: any, index: any) {
      const label = this.series?.[index]?.name;
      if (label) {
        this.$emit("unselect", label);
      }
    },
    calculateSeries() {
      if (!this.stats) return [];
      return Object.entries(this.stats || {})
        ?.map(([key, value]) => {
          if (value?.prediction) {
            return {
              name: `${key} (with prediction)`,
              type: "line",
              color: getLabelColor(key),
              data:
                value.prediction?.map((x: any) => ({
                  x: isNaN(x["date_from"]) ? Date.parse(x["date_from"]) : x["date_from"],
                  y: x["monthly_searches"],
                  forecasted: x.is_forecast,
                })) ?? [],
            };
          } else {
            return {
              name: key,
              type: "line",
              color: getLabelColor(key),
              data:
                value?.volumes?.map((x: VolumeDataPoint) => ({ x: x.date_from, y: x.monthly_searches })) ??
                [],
            };
          }
        })
        .filter((x) => x?.data?.length > 0);
    },
    createAnnotationOptions(series: any[] | undefined) {
      const predictionData = (series ?? this.series)
        .filter((x) => x.name.includes("prediction"))?.[0]
        ?.data?.filter((x: any) => x.forecasted);
      const firstDayOfForecast = new Date(predictionData?.[0]?.x).getTime();
      const lastDayOfForecast = new Date(predictionData?.slice(-1)?.[0]?.x).getTime();
      return {
        xaxis: lastDayOfForecast
          ? [
              {
                x: firstDayOfForecast,
                x2: lastDayOfForecast,
                fillColor: "#8200FF",
                borderColor: "#8200FF",
                opacity: 0.05, // Adjust the opacity to your preference
                label: { text: "Forecasting period", style: { color: "#8200FF" } },
              },
            ]
          : undefined,
      };
    },
    calculateOptions() {
      return {
        chart: {
          id: "LabelVolumeChart",
          toolbar: {
            show: true,
            tools: {
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
              customIcons: [],
            },
          },
          animations: {
            enabled: false,
          },
        },
        title: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "100%",
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: undefined,
          width: 2,
        },
        yaxis: [
          {
            title: {
              text: "Searches / month",
            },
            seriesName: "Search volume",
            forceNiceScale: true,
            decimalsInFloat: 0,
            offsetY: 1000,
            min: 0,
          },
        ],
        tooltip: {
          shared: true,
          followCursor: true,
        },
      };
    },
  },
});
</script>
